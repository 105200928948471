/* eslint-disable no-console */
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import includes from 'lodash/includes';

import {
  ReservedAuthNotAuthorizedError,
  ReservedImpersonateModeError,
  ReservedImpersonateModeMessage,
  ReservedInteractionRequiredAuthError,
} from 'types/app';
import { IdentifierTypes, InternalDomains } from 'types/user';
import { vegaNotifyToast } from 'utility/helper/helperFunctions';

const reactPlugin = new ReactPlugin();

const aiConfig = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.NEXT_PUBLIC_APPINSIGHTS_ID,
    extensions: [reactPlugin],
    enableAutoRouteTracking: false,
    sessionRenewalMs: 3200000,
    extensionConfig: {
      [reactPlugin.identifier]: { history: null },
    },
    autoTrackPageVisitTime: false,
  },
});

const trackEvent = (event, customValues, appInsightsInstance) => {
  if (customValues.userId) {
    aiConfig.context.user.id = customValues.userId;
  }

  if (customValues.error?.response != null) {
    let status = JSON.stringify(customValues.error.response?.data ?? {});
    if (status.indexOf(ReservedImpersonateModeError) !== -1) {
      vegaNotifyToast(ReservedImpersonateModeMessage, 'warning', 4000);
      return;
    }

    if (status.indexOf(ReservedInteractionRequiredAuthError) !== -1) {
      return;
    }

    if (status.indexOf(ReservedAuthNotAuthorizedError) !== -1) {
      return;
    }
  }

  if (process.env.NEXT_PUBLIC_TRACKING === 'TRUE') {
    if (appInsightsInstance) {
      try {
        appInsightsInstance.trackEvent({ name: event, properties: customValues });
      } catch (error) {
        console.log("Couldn't post to appInsights", error);
      }
    }
    try {
      // eslint-disable-next-line no-undef
      dataLayer.push({ event: event, properties: customValues });
    } catch {
      console.log("Couldn't post to GA");
    }
  }
};

const IS_BROWSER = typeof window !== 'undefined';
if (IS_BROWSER) {
  aiConfig.loadAppInsights();
}

export { aiConfig, reactPlugin };

function initializeUserTracking(identifier, user) {
  if (process.env.NEXT_PUBLIC_TRACKING === 'TRUE') {
    try {
      const userEmailDomain = user.userEmail.split('@')[1];
      const accountType =
        user.activeCustomer?.idType === IdentifierTypes.MERCHANT_ID
          ? 'Payments+'
          : user.activeCustomer?.idType === IdentifierTypes.CLIENT_ID
            ? 'Payroll+'
            : '';

      const isInternal = includes(InternalDomains, userEmailDomain);

      const pendoInitializeObject = {
        visitor: {
          id: identifier.toLowerCase(),
          ma_role: user.permissionSet.name.toUpperCase(),
          ma_is_global_employee: isInternal,
          ma_is_verified: user.isVerified,
        },
      };

      if (!isInternal) {
        pendoInitializeObject.visitor['ma_payroll_accounts'] = user.userHasActivePayrollAccounts;
        pendoInitializeObject.visitor['ma_payments_accounts'] = user.userHasActivePaymentsAccounts;
      }

      if (user.activeCustomer?.idValue && !isInternal) {
        pendoInitializeObject.account = {
          id: user.activeCustomer?.idValue,
          ma_account_type: accountType,
          ma_business_name: user.activeCustomerInformation?.dbaName ?? 'N/A',
          ma_account_terminated: user.activeCustomerInformation?.customerTerminated,
          ma_has_valid_owner: user.activeCustomerInformation?.ownerEmails.length > 0,
          ma_high_risk_mcc: user.activeCustomerInformation?.highRisk,
          ma_mcc: user.activeCustomerInformation?.mcc,
        };
      }

      window.pendo.initialize(pendoInitializeObject);
    } catch (error) {
      console.log(error);
      console.log('Could not initialize tracking in Pendo.');
    }
  }
}

const tracker = {
  trackEvent,
  initializeUserTracking,
};

export default tracker;
