export enum Events {
  CART_UPDATE = 'MA_CART_UPDATE',
  CHART_ERROR = 'MA_CHART_ERROR',
  VIEW_DETAILS = 'MA_VIEW_DETAILS',
  CHECKOUT = 'MA_CHECKOUT',
  BEGIN_CHECKOUT = 'MA_BEGIN_CHECKOUT',
  APPLICATION_SELECT = 'MA_DASHBOARD_APP_SELECT',
  SUPPORT = 'MA_SUPPORT',
  ZENDESK = 'MA_ZENDESK',
  CLIENT_ERROR = 'MA_CLIENT_ERROR',
  GRAPH_SELECTED = 'MA_GRAPH_SELECTED',
  AUTH_ERROR = 'MA_AUTH_ERROR',
  AUTH_REDIRECT_TOKEN_REFRESH = 'MA_AUTH_REDIRECT_TOKEN_REFRESH',
  VIEW_DOCUMENT = 'MA_DOCUMENT_VIEW',
  TAX_RATE_NOT_FOUND = 'MA_TAX_RATE_NOT_FOUND',
  REMOVED_SGA_SESSIONS = 'REMOVE_SGA_SESSIONS',
  ERROR_INFO = 'ERROR_INFORMATION',
  USER_INVITED = 'USER_INVITED',
  SGA_ERROR_RESULT = 'SGA_ERROR_RESULT',
  USER_LOGIN_SUCCESS = 'MA_USER_LOGIN_SUCCESS',
  AXIOS_FETCH_ERROR = 'AXIOS_UNABLE_TO_FETCH',
}
