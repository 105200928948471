import { CompleteOrder } from './cart';
import { ShippingTypes } from './checkout';

//Used by equipment details page and others
export type EquipmentPackageListItem = {
  packageId: number;
  name: string;
  shortName?: string;
  groupedName?: string;
  productCategory?: string;
  detailsHtml: string;
  salesText?: string;
  allowMultipleConfigurations: boolean;
  features: Array<Feature>;
  packagePrices: Array<PackagePrice>;
  imageUrls: Array<ImageUrl>;
  maxQuantity: number;
  minQuantity: number | null;
  ordinal: number;
  extendedSalesText?: string;
  deviceDescription?: string;
  allowSubscription: boolean;
  packageShippingMethods: Array<PackageShippingMethod>;
  childPackages: Array<EquipmentPackageListItem>;
  subscriptionName?: string;
  hasAccessories: boolean;
  packageEquipment: Array<packageEquipment>;
  packageAccessories: Array<Accessory>;
  isDisabled: boolean;
};

//Used by equipment list page
export type SimpleEquipmentPackage = {
  packageId: number;
  groupedName?: string;
  name: string;
  detailsHtml: string;
  packagePrices: Array<PackagePrice>;
  imageUrls: Array<ImageUrl>;
  ordinal: number;
  childPackages: Array<SimpleEquipmentPackage>;
};

export type Feature = {
  ordinal: number;
  description: string;
  fontAwesomeIcon: string;
};

export type ImageUrl = {
  ordinal?: number;
  thumbnail?: string;
  hero?: string;
  large?: string;
  video?: string;
};

export type PackagePrice = {
  packagePriceId?: number;
  packageId?: number;
  price?: number;
  name?: string;
};

export type PackageShippingMethod = {
  packageShippingMethodId: number;
  packageId: number;
  name: string;
  price: number;
  shippingMethod?: ShippingTypes;
  isSelected: boolean;
};

export type Subscription = {
  isActive: boolean;
  equipmentPackageId: number;
  packagePriceId: number;
  subscriptionName: string;
  recurrenceCount: string;
  recurrenceFrequency: string;
  skipNextOrder: boolean;
  createdBy: string;
  createdUTC: string;
  lastUpdatedBy: string;
  lastUpdatedUTC: string;
  lastProcessed: string;
  nextProcessDate: string;
  lastUpdatedUserEmail?: string;
};

export type RemoveSubscriptionPayload = {
  packageId: number;
  packagePriceId: number;
};

export type ModifySubscriptionPayload = {
  subscriptionName: string;
  packageId: number;
  packagePriceId: number;
  recurrenceFrequency: string;
  recurrenceCount: number;
  lastUpdatedUTC: string;
  skipNextOrder: boolean;
};

export type CompleteOrderPayload = CompleteOrder & {
  recaptchaToken: string;
};

export type packageEquipment = {
  equipmentId: number;
  ordinal: number;
  quantity: number;
  equipment: Equipment;
};

export type Equipment = {
  equipmentId: number;
  name: string;
  fullName?: string;
  description: string;
  logisticsDID: number;
  imageUrls: Array<string>;
  equipmentConfigQuestions: Array<ConfigurationQuestion>;
  equipmentConnectivityFeatures?: Array<ConnectivityFeature>;
};

export type EquipmentDetail = EquipmentPackageListItem & {
  hasActiveSubscription: boolean;
  activeSubscription?: Subscription;
  isInCartOneTime: boolean;
  isInCartSubscription: boolean;
  packageId: number;
};

export type Accessory = {
  packageAccessoryId: number;
  packageId: number;
  equipmentId: number;
  parentPackageAccessoryId?: number;
  ordinal: number;
  quantity: number;
  equipment: Equipment;
  packageAccessoryPrices: Array<AccessoryPrice>;
};

export type AccessoryPrice = {
  packageAccessoryPriceId: number;
  packageAccessoryId: number;
  name: string;
  price: number;
  priceType: PriceType;
  recurrenceFrequency: PriceRecurrenceFrequency;
};

export type AccessorySelection = {
  packageAccessoryId: number;
  price: number;
  priceType: string;
  name: string;
  recurrenceFrequency?: string;
};

export type ConfigurationOption = {
  equipmentConfigOptionId: number;
  equipmentConfigQuestionId: number;
  isDefaultOption: boolean;
  value: string;
  label: string;
};

export type ConfigurationOptionPayload = {
  equipmentConfigQuestionId: number;
  value: string;
  label: string;
};

export enum ConfigurationType {
  AutoClose = 'AutoClose',
  AdministrationEmail = 'Text',
}

export type ConfigurationQuestion = {
  autoComplete: string;
  fieldType: ConfigurationType;
  fieldDefinition: string;
  fontAwesomeIcon?: string;
  isSharedPackageValue: boolean;
  isRequired: boolean;
  label: string;
  ordinal: number;
  configQuestionOptions: Array<ConfigurationOption>;
  equipmentConfigQuestionId: number;
  questionGroupId: number;
  value: any;
  error: boolean;
  errorText: string;
  complete: boolean;
  open: boolean;
};

export type ConnectivityFeature = {
  description: string;
  fontAwesomeIcon?: string;
  backgroundColor?: string;
  ordinal: number;
};

export type PackageConfiguration = {
  configValues: Array<ConfigurationOptionPayload>;
  accessoriesValues: Array<AccessorySelection>;
  quantity: number;
  cartPackageId?: number;
  uuid?: string;
};
export enum PriceType {
  BasePrice = 'BasePrice',
  SubscriptionPrice = 'SubscriptionPrice',
  FeePrice = 'FeePrice',
}

export enum SubscriptionModalType {
  Skip = 'Cannot modify skip subscription value within 5 hours of process date.',
  Modify = 'Modify',
  Cancel = 'Cancel',
}

export enum PriceRecurrenceFrequency {
  Days = 'NumberOfDays',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export const SubscriptionProcessTime = '5:00 AM';
export const NotAuthorizedNoteText = 'NOTE: Your account does not have permission to purchase equipment.';
export const SubscriptionTextKey = 'Subscription';
export const detailsLayoutContainer = 'details-layout-container';
