export type SupportItem = {
  name: string;
  iconPath: string;
  phoneNumber?: string;
  email?: string;
  actionName?: string;
  actionUrl?: string;
  isContactUsOption: boolean;
  actionType?: ActionType;
};

export enum ActionType {
  url = 'Url',
  phone = 'Phone',
  chat = 'Chat',
  email = 'Email',
}
